











import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import Calculator from '@/shared/modules/calculator/calculator'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import EditAction from '@/components/data-table/default-actions/edit-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import DeleteAction from '@/components/data-table/default-actions/delete-action'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import http from '@/shared/http'
import Rule from '@/shared/modules/rule/rule'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Client from '@/shared/modules/client/client'
import CreateRuleDialog from '@/components/rules/CreateRuleDialog.vue'
import EditRuleDialog from '@/components/rules/EditRuleDialog.vue'
import DataTable from '@/components/data-table/DataTable.vue'
import { RuleType } from '@/shared/configs/rule-types'
import capitalize from '@/shared/helpers/capitalize'
import Tabs from '@/components/tabs/Tabs.vue'
import Tab from '@/shared/classes/tabs/tab'
import { Routes } from '@/shared/router'
import DataTableDefaultFilterInterface from '@/shared/interfaces/data-table-default-filter.interface'
import _ from 'lodash'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'

@Component({
  components: { Tabs, DataTable }
})
export default class Rules extends Vue {
  @Inject('calculator') getCalculator!: () => Calculator
  @Watch('$route.path', { immediate: true }) private handleRouteFilterChanges() {
    const filter: DataTableDefaultFilterInterface | null = this.$router.currentRoute.meta.filter

    this.table?.setDefaultFilters(filter ? [ filter ] : [])
  }

  tabs: Tab[] = [
    new Tab()
      .setTitle('All Rules')
      .setName(Routes.calculatorRulesAll),
    new Tab()
      .setTitle('Notes')
      .setName(Routes.calculatorRulesNotes),
    new Tab()
      .setTitle('Refers')
      .setName(Routes.calculatorRulesRefers),
    new Tab()
      .setTitle('Declines')
      .setName(Routes.calculatorRulesDeclines)
  ]

  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setEndpoint(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/rules`)
      .setHeaders([
        new DataTableHeader()
          .setKey('type')
          .setTitle('Type')
          .setValueParser(capitalize)
          .setClasses('status-badge')
          .setStyleParser((value: string) => {
            let background, color

            switch (value) {
              case RuleType.note:
                background = 'rgba(94, 129, 244, 0.4)'
                color = this.$vuetify.theme.themes.light.secondary
                break
              case RuleType.refer:
                background = 'rgba(245, 204, 95, 0.4)'
                color = 'rgba(245, 204, 95, 1)'
                break
              case RuleType.decline:
                background = 'rgba(255, 128, 139, 0.4)'
                color = 'rgba(255, 128, 139, 1)'
                break
            }

            return {
              container: {
                background,
                color,
                borderRadius: '4px',
                padding: '4px 15px',
              }
            }
          }),
        new DataTableHeader()
          .setKey('code')
          .setTitle('Code'),
        new DataTableHeader()
          .setKey('name')
          .setTitle('Name'),
        new DataTableHeader()
          .setKey('description')
          .setTitle('Description'),
      ])
      .setActions([
        new EditAction()
          .setAction((rule: Rule) => {
            this.$store.dispatch(GlobalActions.showDialog, {
              maxWidth: 1500,
              component: EditRuleDialog,
              meta: {
                client: this.selectedClient,
                calculator: this.getCalculator(),
                rule,
                onSuccess: () => {
                  this.tableRef.refresh()

                  this.$store.dispatch(GlobalActions.showSnackbar, {
                    type: SnackBarType.success,
                    message: 'Rule was successfully updated'
                  })
                }
              }
            })
          }),
      new DeleteAction()
          .setAction((rule: Rule) => {
            this.$store.dispatch(GlobalActions.showDialog, {
              maxWidth: 400,
              component: AreYouSureDialog,
              meta: {
                onYes: () => http.delete(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/rules/${ rule.uuid }`)
                  .then(() => {
                    this.tableRef.refresh()

                    this.$store.dispatch(GlobalActions.showSnackbar, {
                      type: SnackBarType.success,
                      message: 'Rule was successfully deleted'
                    })
                  })
              }
            })
          })
      ])
  }

  openAddDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 1300,
      component: CreateRuleDialog,
      meta: {
        client: this.selectedClient,
        calculator: this.getCalculator(),
        onSuccess: () => {
          this.tableRef.refresh()

          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'Rule was successfully created'
          })
        }
      }
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
