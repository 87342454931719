import Field from '@/shared/classes/form/field'
import { FieldType } from '@/shared/configs/field-type.config'

export default class ArrayField extends Field {
  type: FieldType = FieldType.array
  children: Field[] = []
  firstDelete: boolean = true
  additionalActions: any[] = []
  addButton: (value: any, entry: any) => boolean = (value: any, entry: any) => true

  setChildren(children: Field[]): this {
    this.children = children
    return this
  }

  setFirstDelete(firstDelete: boolean): this {
    this.firstDelete = firstDelete
    return this
  }

  setAdditionalActions(components: any[]): this {
    this.additionalActions = components
    return this
  }

  setAddButton(addButton: (value: any, entry: any) => boolean): this {
    this.addButton = addButton
    return this
  }
}
