














import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSize } from '@/shared/classes/form/field'
import SelectField from '@/shared/classes/form/fields/select/select-field'
import { ruleTypes } from '@/shared/configs/rule-types'
import { FieldType } from '@/shared/configs/field-type.config'
import { GlobalActions } from '@/shared/store/global/global.actions'
import PageHeader from '@/components/PageHeader.vue'
import can from '@/shared/helpers/can'
import { Permission } from '@/shared/configs/permission.config'
import Form from '@/components/form/Form.vue'
import HttpMethod from '@/shared/configs/http-method.config'
import ArrayField from '@/shared/classes/form/fields/array-field'

@Component({
  components: { PageHeader, Form },
  methods: { can }
})
export default class EditRuleDialog extends Vue {
  @Prop() meta!: any
  Permission = Permission

  form: FormBase = new FormBase()
    .setMethod(HttpMethod.PUT)
    .setUuid(this.meta.rule.uuid)
    .setEndpoint(`clients/${ this.meta.client.uuid }/calculators/${ this.meta.calculator.uuid }/rules`)
    .setFields([
      new Field()
        .setKey('code')
        .setTitle('Code')
        .setSize(FieldSize.quarter),
      new SelectField()
        .setKey('type')
        .setTitle('Type')
        .setSize(FieldSize.quarter)
        .setItems(ruleTypes),
      new Field()
        .setKey('name')
        .setTitle('Name')
        .setSize(FieldSize.half),
      new Field()
        .setType(FieldType.comboBox)
        .setTitle('Keys')
        .setKey('keys'),
      new Field()
        .setType(FieldType.textArea)
        .setRows(3)
        .setKey('description')
        .setTitle('Description'),
      new ArrayField()
        .setKey('translations')
        .setTitle('Translations')
        .setChildren([
          new SelectField()
            .setKey('locale_uuid')
            .setTitle('Locale')
            .setSize(FieldSize.twoTwelfth)
            .loadItems({
              endpoint: `locales`,
              value: 'uuid',
              title: 'name',
            })
            .setClasses('mr-4'),
          new Field()
            .setKey('name')
            .setTitle('Name')
            .setSize(FieldSize.tenTwelfth),
          new Field()
            .setType(FieldType.textArea)
            .setRows(3)
            .setKey('description')
            .setTitle('Description'),
          ])
          .setFirstDelete(true)
    ])
    .setSubmit(false)
    .setInitialValues({
        keys: [],
    })

  async submit() {
    const errors = await this.formRef.submit()
    if (errors) return

    this.closeDialog()
    this.meta.onSuccess && this.meta.onSuccess()
  }

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get formRef(): any {
    return this.$refs.form
  }
}
